/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nunito';
  src: url('assets/fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Light';
  src: url('assets/fonts/Nunito-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-Bold';
  src: url('assets/fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.quovo-font-light {
  font-family: 'Nunito-Light';
}

.quovo-font-bold {
  font-family: 'Nunito-Bold';
}
body .container-fluid {
  // padding: 20px 44px 0 44px;
}
html body {
  font-family: "Nunito" !important;
    background-color: #F3F3F3!important;
}
.collapse .accordion-body {
  visibility: visible;
}